<template>
   <section>
     <section class="section1">
        <div class="buy-coins container">
            <div class="coins-left">
                <div class="title"><span class="logo">{{sysconfig.name}}</span></div>
                <div class="title"><span class="descript">{{$t('home.home_need')}}</span></div>
                <ul>
                    <li>{{$t('home.home_need')}}</li>
                    <li>{{$t('home.home_need')}}</li>
                    <li>{{$t('home.home_need')}}</li>
                    <li>{{$t('home.home_need')}}</li>
                </ul>
            </div>
            <div class="coins-img">
                    <img  src="@/assets\images\eu1\candle@2x.png" alt="" />
            </div>
            <div class="coins-img1">
                    <img  src="@/assets\images\eu1\candle.png" alt="" />
            </div>
        </div>         
    </section>
    <section class="section2"> 
        <div class="content">
           <router-link to="/user/create" class="d-flex align-items-center">
                    {{ $t("home.guide_register") }}
            </router-link>
        </div>
    </section>
    <section class="section3"> 
        <div class="left">
        <div class="title"><span class="logo">{{sysconfig.name}}</span></div>
        <div class="title1"><span class="descript">{{$t('home.h1')}}</span></div>
        <div class="imgs">
            <img src="@/assets/images/eu1/coins_one.png" />
            <img src="@/assets/images/eu1/coins_two.png" />
            <img src="@/assets/images/eu1/coins_three.png" />
            <img src="@/assets/images/eu1/coins_four.png" />
        </div>       
        </div>
        <div class="right">
        </div>
        <img class="bgimg" src="@/assets/images/eu1/trend@2x.png" />
    </section>
    <section class="section4"> 
        <div class="content container">
            <div class="left">
                <div class="title">
                    <span class="descript">{{$t('home.h1')}}</span>
                </div>
                <div class="title1">{{$t('home.home_helps')}}</div>
                <div class="jump">
                    <router-link to="/user/create" class="d-flex align-items-center">
                        {{ $t("home.guide_register") }}
                    </router-link>
                </div>
            </div>
            <div class="right">
               <img src="@/assets/images/eu1/shopping@2x.png" /> 
            </div>
        </div>
    </section>
    <section class="section5"> 
        <div class="content container">
            <div class="palm-bottom">
            <h2>{{ $t('home.footer_feedback') }}</h2>
            <div class="content">
                <div class="one">
                    <div class="title">{{ $t('home.footer_services') }}</div>
                    <div class="Sub-label">{{ $t('home.risk_notice_line3') }}</div>
                </div>
                <div class="two">
                    <div class="title">{{ $t('home.footer_services') }}</div>
                    <div class="Sub-label">{{ $t('home.risk_notice_line3') }}</div>
                </div>
                <div class="three">
                    <div class="title">{{ $t('home.footer_services') }}</div>
                    <div class="Sub-label">{{ $t('home.risk_notice_line3') }}</div>
                </div>
            </div>
        </div>
        </div>
    </section>
   </section>
</template>
<script>
export default {
    data() {
        return {
           
        }
    },
    created() { },
    methods: {

    }
}
</script>
<style lang="less" scoped>
ul,li{
    margin: 0;
    padding: 0;
}
.section1{
        .buy-coins{
            border-radius: 0px 0px 0px 0px;
            width: 100%;
            min-height: 450px;
            display: flex;
            padding: 0 10px;
            .coins-left,.coins-img{
                width: 50%;
            }
            .coins-left{
                padding-top: 10%;
                padding-left: 9%;
                padding-right: 20px;
                padding-bottom: 20px;
                color: #000;
                .title{
                    font-weight: 500;
                    font-size: 22px;
                    .img{
                        width: 100px;
                        height: 50px;
                    }
                }
                ul{
                    margin-left: 15px;
                    font-size: 12px;
                    li{
                        margin: 5px 0;
                    }
                }
            }
            .coins-img1{
                display: none;
            }
            .coins-img{
                text-align: center;
                padding: 10px;
                img{
                    width: 390px;
                    height: 400px;
                }
            }
    }
}
.section2{
    .content{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #A3DEE6;
        .align-items-center{
            width: 100px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 165px 165px 165px 165px;
            border: 1px solid #000000;
        }
    }
}
.section3{
    position: relative;
    width: 100%;
    min-height: 500px;
    display: flex;
    .left,.right{
        height: 500px;
        width: 50%;
    }
    .left{
        padding: 5% 60px 20px  20%;
        background: #BEF1F4FF;
        .title{
            font-weight: 500;
            font-size: 22px;
        }
        .title1{
            font-weight: 600;
            font-size: 22px;
        }
        .imgs{
            display: flex;
            margin-top: 20px;
            img{
                margin-right: 10px;
            }
        }
    }
    .bgimg{
        position: absolute;
        top: 50px;
        left: 44%;
        width: 600px;
    }

}
.section4{
    width: 100%;
    .content{
        width: 100%;
        display: flex;
        .left,.right{
            width: 50%;
            height: 350px;
        }
        .right{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .left{
            padding-top: 5%;
            padding-left: 9%;
            padding-right: 20px;
            padding-bottom: 20px;
            .title{
                font-weight: 500;
                font-size: 22px;
            }
            .title1{
                font-size: 14px;
                color: #7F8490;
                margin-top: 20px;
                margin-bottom: 30px;
            }
            .jump{
                a{
                    background: #333333FF;
                    width: 100px;
                    height: 40px;
                    border-radius: 165px 165px 165px 165px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                }
            }
        }
    }
} 
.section5{
        .palm-bottom{
            width: 100%;
            min-height: 280px;
            margin-top: 40px;
            margin-bottom: 10px;
            h2{
                font-weight: 600;
                text-align: center;
                margin-bottom: 20px;
            }
            .content{
                display: flex;
                width: 100%;
                .one,.two,.three{
                    width: 32%;
                    margin: auto;
                    height: 250px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 59px 0px rgba(0,0,0,0.07);
                    padding: 20px 10px 20px 10px;
                    >div:nth-child(1){
                            display: flex;
                            align-items: center;
                            justify-content: center;
                    }
                }
                .one{
                    .title{
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }
                }
                .two{
                    .title{
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }
                }
                .three{
                    .title{
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }
                }
            }
    }
}


@media (max-width: 992px) {
}
@media (max-width: 1024px)
{   
    .section1{
        .buy-coins{
            min-height: 350px;
            .coins-left{
                width: 70%;
                padding-top: 50px;
                padding-left: 20px;
            }
            .coins-img1{
                display: block;
                width: 30%;
                display: flex;
                align-items: center;
                img{
                    width: 110px;
                }
            }
            .coins-img{
                display: none;
            }
        }
    }
    .section3{
        min-height: 400px;
        display: flex;
        flex-direction: column;
        .left{
            width: 100%;
            height: 200px;
        }
        .right{
            width: 100%;
            height: 300px;
        }
        .left{
            padding: 20px 20px 20px 10%;
        } 
        .bgimg{
            position: absolute;
            top: 200px;
            left: 0;
            right: 0;
            width: 100%;
            max-width: 375px;
            margin: auto;
        }
    }
    .section4{
        .content{
            width: 100%;
            display: flex;
            flex-direction: column;
            .left,.right{
                width: 100%;
                height: 300px;
           }
           .right{
                img{
                    width: 350px;
                    height: 280px;
                }
           }
        }
    }
    .section5{
         .palm-bottom{
            .content{
                display: flex;
                flex-direction: column;
                .one,.two,.three{
                    width: 100%;   
                    margin-bottom: 20px; 
                }
            }
         }
    }
}

</style>
