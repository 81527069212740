<template>
    <section class="page balance-page">
        <div class="top-header w-100">
            <div class="container">
                <h1 class="d-none d-md-block">{{ $t('assets.assets_property') }}</h1>
                <div class="row">
                    <div class="col">
                        <div class="top-header-content d-flex align-items-center">
                            <div class="w-100">
                                <div class="assets">
                                    <div class="assets-left">
                                        <div class="flex-column assets-left-mb">
                                            <div class="col">
                                                <h6>{{ $t('assets.total_assets') }}</h6>
                                            </div>
                                            <div class="d-flex">
                                                <div class="totalMoneyStyle">
                                                    {{ parseFloat(totalMoney).toFixed(4) +' USDT' }}
                                                </div>
                                            </div>
                                            <div class="row balance-actions">
                                                <div class="col">
                                                    <router-link to="/finance/orders" id="orders-btn">
                                                        {{ $t('balance.orders_title') }}
                                                    </router-link>
                                                </div>
                                                <div class="col">
                                                    <router-link to="/finance/history">
                                                        {{ $t('balance.history_title') }}
                                                    </router-link>
                                                </div>
                                                <div class="col">
                                                    <a href="javascript:;" @click="showExchangeModal">
                                                        {{ $t('assets.label_exchange') }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Do not display deposit/withdraw buttons when multi-coin supports are enabled. -->
                                <div v-if="bcConfig && !bcConfig.supportMultiCoinDeposits" class="mt-4">
                                    <a href="javascript:;" class="btn btn-primary ps-4 pe-4" @click="toggle_deposit()">
                                        {{ $t('general.deposit') }}
                                    </a>
                                    <router-link class="btn btn-secondary ps-4 pe-4" to="/finance/withdraw">{{
                                        $t('general.withdraw') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-block d-md-none">
            <div class="balance-middle-content">
                <div class="middle-left-box">
                    <img class="middle-left-img" src="../../assets/images/de-twaa/home-middle-left-2x.png" alt="">
                </div>
                <div class="balance-middle-desp">
                    <div class="middle-title-box">
                        <h3 class="middle-title">{{ $t('intro.title') }}</h3>
                        <div class="middle-title-desp">{{ $t('intro.safe') }}</div>
                    </div>
                    <ul class="middle-desp-list">
                        <li class="middle-desp-item">{{ $t('intro.safe_desp') }}</li>
                        <li class="middle-desp-item">{{ $t('intro.listing_desp') }}</li>
                        <li class="middle-desp-item">{{ $t('intro.rich_desp',{platform:sysconfig.name}) }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <section v-if="bcConfig" class="w-100">
            <div class="container px-3 py-5">

                <!-- balance list here -->
                <balance-list-multi-coins v-if="bcConfig.supportMultiCoinDeposits === true" :balance_list="balance_list"
                    :futures_assets="futuresAssets" :bcConfig="bcConfig" />
                <balance-list v-else :balance_list="balance_list" :show_deposit_address="show_deposit_address"
                    :futures_assets="futuresAssets" :bcConfig="bcConfig" />
            </div>

            <futures-stats ref="futuresStats" @balance-updated="onFuturesStatsUpdated" />
            <asset-exchange-modal ref="exchangeModal" :balances="balance_list" :bonusToken="bonusToken"
                @balance-updated="onBalanceUpdated" />
        </section>
    </section>
</template>

<script>
import AssetExchangeModal from './Components/AssetExchangeModal.vue';
import FuturesStats from '../Components/FuturesOpenOrderStats.vue';
import BalanceList from './BalanceComponents/_BalanceList-4.1.vue';
import BalanceListMultiCoins from './BalanceComponents/_BalanceList-MultiCoins.vue';

import { setProfile } from 'utilities/helper';
let g_assetTypes = [];
let g_assetTypeMap = {};

export default {
    components: { AssetExchangeModal, FuturesStats, BalanceList, BalanceListMultiCoins },

    data() {
        return {
            balance_list: null,
            bonusToken: null,
            futuresAssets: '--',

            // Config settings for blockchain
            bcConfig: null,

            // Indicates whether to display deposit addresses or not.
            show_deposit_address: false,

            //top-header user information
            profile: null,
            //totalMoney
            totalMoney: 0,
            showTotalMoney: true,
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
        this.initAsync();
    },
    computed: {
        userPhone() {
            return this.profile.phone
        },
        userUid() {
            return this.profile.referralCode
        },
    },
    methods: {
        initAsync: async function () {
            ///////////////////////////////////////////////////////////////////////////////////////
            // Get all asset types.
            const assetTypes = [];
            const assetTypeMap = {};

            // USDT and futures margin account will already be displayed at top
            assetTypes.push('USDT');
            assetTypes.push('FTUSDT');
            assetTypeMap['USDT'] = [];
            assetTypeMap['FTUSDT'] = [];

            // Then query blockchain config
            const resp = await $.callPostApi(this, '/api/v1/blockchain/config');
            if (!resp || !resp.data) {
                // exit for invalid config
                console.error('Invalid blockchain config.');
                return;
            }

            if (resp.data.supportedCoins) {
                $(resp.data.supportedCoins).each((index, item) => {
                    // Always use upper case
                    const upperCoin = item.currency.toUpperCase();

                    if (!assetTypeMap[upperCoin]) {
                        assetTypes.push(upperCoin);
                    }
                    assetTypeMap[upperCoin] = item;
                });
            }

            g_assetTypes = Object.freeze(assetTypes);
            g_assetTypeMap = Object.freeze(assetTypeMap);
            this.bonusToken = resp.data.bonusToken;
            this.bcConfig = Object.freeze(resp.data);

            ///////////////////////////////////////////////////////////////////////////////////////
            // Update balance list finally
            await this.syncBalancesAsync();
        },

        toggle_deposit: function () {
            this.show_deposit_address = !this.show_deposit_address;
        },

        syncBalancesAsync: async function () {
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            const map = {};
            $(balances).each((index, raw_balance) => {
                map[raw_balance.currency] = new BalanceItem(raw_balance);
            });

            // Have configured tokens display at top
            const output = [];
            let ftItem = null;
            $(g_assetTypes).each((index, currency) => {
                let item = map[currency];
                if (!item) {
                    item = new BalanceItem({ currency: currency, balance: 0, frozen: 0 });
                } else {
                    delete map[currency];
                }
                if (currency === 'FTUSDT') {
                    ftItem = item;
                } else {
                    output.push(item);
                }
            });

            // Any remaining balance items?
            for (let currency in map) {
                output.push(map[currency]);
            }

            // Add futures currency at the last one
            if (ftItem) {
                output.push(ftItem);
            }

            self.balance_list = output;

            //get USDT totalMoney
            for (let balance in balances) {
                if (balances[balance].displayName == 'USDT') {
                    self.totalMoney = balances[balance].balance;
                }

            }
        },

        onBalanceUpdated: function () {
            const self = this;
            this.balance_list = null;

            setTimeout(() => {
                // Update balance, and resync open futures orders.
                self.syncBalancesAsync();
                self.$refs.futuresStats.reload();
            }, 500);
        },

        showExchangeModal: function () {
            this.$refs.exchangeModal.showModal();
        },

        /**
         * Update the available amount for the futures account.
         */
        onFuturesStatsUpdated: function () {
            const stats = this.$refs.futuresStats.getStats();
            this.futuresAssets = isNaN(stats.current_balance) ? '--' : stats.current_balance.toFixed(4);
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;

            }
        },
        //display xxx***xx str
        formatString(str) {
            // get str length
            const length = str.length;
            // Define the length of prefixes and suffixes to retain
            const prefixLength = 3;
            const suffixLength = 2;
            // Determine whether the string is long enough
            if (length <= prefixLength + suffixLength) {
                return str;
            }
            // Get prefix and suffix
            const prefix = str.slice(0, prefixLength);
            const suffix = str.slice(length - suffixLength);

            // Final result of splicing
            return `${prefix}***${suffix}`;
        },
    }
};

/**
 * BalanceItem
 */
function BalanceItem(balance) {
    // this.__raw_data = balance;

    // is the symbol associated to an exchange one?
    // const sym = _exchange_symbol_name_map[balance.currency];
    // const precision = sym ? sym.volumePrecision : 2;

    // hard-coded to use 4 precision
    const precision = 4;

    this.available = (balance.balance + balance.frozen).formatBalance(precision);
    this.balance = balance.balance.formatBalance(precision);
    this.frozen = (-balance.frozen).formatBalance(precision);
    // this.incentive = balance.incentive.floor(precision) * 1;

    this.display_name = balance.displayName || balance.currency;
    this.currency = balance.currency;
}
</script>
<style scoped>
.page {
    background: #F5F5F6;
}
.balance-middle-content {
    display: flex;
    width: 100%;
    margin: 40px 0 0;
    align-items: center;
}
.middle-left-box {
    width: 50%;
    height: 100%;
}
.middle-left-img {
    width: 100%;
    height: auto;
}
.middle-title-box {
    margin: 11px 0 40px;
}
.balance-middle-desp {
    width: 50%;
}
.middle-title {
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    line-height: 32px;
}
.middle-title-desp {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 17px;
}
.middle-desp-item {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    line-height: 22px;
    margin-bottom: 20px;
}
.middle-desp-item::marker {
    color: #132EAF;
}
.top-header h1 {
    font-weight: normal;
    font-size: 24px;
    color: #000000;
    margin: 70px 0 50px;
}
.mt-4 a,
.balance-actions a {
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #FFFFFF;
    padding: 10px 21px;
}
#orders-btn{
    background: #0362FA;
    color: #fff;
    border: none;
}
.mt-4 a:hover,
.balance-actions a:hover {
    background: #0C2780;
    color: #fff;
}

.balance-actions .col {
    flex: 0 0 auto;
    width: auto;
}
.top-header-content {
    background: #000;
    border-radius: 40px;
    padding: 60px 40px;
    background-image: url(../../assets/images/de-twaa/assetsBc.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 15%;
}

.balance-actions a:hover {
    background: #11CF8B;
}

.transfer a {
    color: #000;
}
.total-assets {
    display: flex;
    align-items: center;
    padding: 2rem 0;
}

.user-info {
    display: flex;
    justify-content: flex-start;
}

.user-info-item {
    display: flex;
    margin-left: 20px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E1E2E6;
    padding: 5px 8px 5px 8px;
    margin-top: 5px;
}
.user-box-left {
    display: flex;
    align-items: center;
}

.avatar-md {
    width: 30px;
    margin-right: 5px;
}

.user-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 21px;
    color: #111111;
    line-height: 17px;
    margin-bottom: 5px;
}

.login-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 18px;
}

.user-box-btn {
    border-radius: 20px;
    border: 1px solid #18191C;
    padding: 8px 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000922;
    line-height: 10px;
    cursor: pointer;
}

.user-info-box-md {
    display: flex;
    flex-wrap: wrap;
}

.user-info-box-md .user-info-item {
    display: block;
    margin: 0 0 20px;
    flex: 1 0 100px;
    min-width: 100px;
    max-width: 100%;
}

.item-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #7F8490;
    line-height: 22px;
}

.item-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #18191C;
    line-height: 22px;
}

.totalMoneyStyle {
    font-weight: normal;
    font-size: 28px;
    color: #11CF8B;
    line-height: 32px;
    margin-bottom: 24px;
}

.assets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-radius: 20px 20px 20px 20px;
}

.assets-left {
    display: flex;
    flex: 1;
    justify-self: flex-end;
    justify-content: space-between;
    align-items: center;
}
.assets-left h6 {
    font-weight: normal;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    margin-bottom: 20px;
}
.balance-actions {
    padding-bottom: 0;
}
@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }

    .assets {
        background-repeat: no-repeat;
        min-height: 100px;
        background-size: 100% 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
    }

    .mb-title h1,
    .assets-left h1 {
        font-family: PingFang SC, PingFang SC;
        font-size: 20px;
    }
    .assets-left{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0;
        padding-left: 0;
    }
    .totalMoneyStyle {
        font-size: 22px;
    }

    .row .balance-actions {
        flex-wrap: wrap;
        margin: 0;
    }

    .total-assets {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        padding-left: calc(var(--bs-gutter-x)* 0.5);
    }
    .top-header-content {
        background-size: 25%;
        margin-top: 20px;
    }
}
@media (max-width: 768px) {
    .middle-title {
        font-size: 16px;
        line-height: 20px;
    }
    .middle-desp-item {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 5px;
    }
    .middle-title-box {
        margin-bottom: 10px;
    }
}
</style>