import { render, staticRenderFns } from "./_PromptedSymbol.vue?vue&type=template&id=50f5d17b&scoped=true"
import script from "./_PromptedSymbol.vue?vue&type=script&lang=js"
export * from "./_PromptedSymbol.vue?vue&type=script&lang=js"
import style0 from "./_PromptedSymbol.vue?vue&type=style&index=0&id=50f5d17b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f5d17b",
  null
  
)

export default component.exports