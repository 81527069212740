<template>
    <div>
        <!-- Fiat deposit description -->
        <div v-if="fiat_desp && fiat_desp.length">
            <div class="alert alert-info">
                <pre style="font-size: 1rem; line-height: 1.5; white-space: pre-wrap; overflow: auto">{{ get_fiat_desp($i18n.locale) }}</pre>
                <a href="javascript:;" onclick="toggle_udesk()" class="toggle-cs">
                    <svg viewBox="0 0 256 256" class="svg-icon">
                        <use xlink:href="/dist/svg/icons.svg#icon-phone" />
                    </svg>
                    {{ $t('general.customer_service_long') }}
                </a>
            </div>
        </div>

        <!-- Special case for USDT -->
        <div v-if="coinConfig.currency === 'USDT'">
            <ul class="nav nav-tabs">
                 <li class="nav-item">
                    <a class="nav-link" href="javascript:;" v-bind:class="{ active: active_currency === 'USDT_TRC' }" @click="toggle_deposit('USDT_TRC')"> USDT-TRC20 </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:;" v-bind:class="{ active: active_currency === 'USDT' }" @click="toggle_deposit('USDT')"> USDT-ERC20 </a>
                </li>
            </ul>
        </div>
        <div class="help-block">{{ $t('balance.assets_deposit_address') }} ({{ active_currency }})</div>

        <!-- Loading -->
        <loading-indicator v-if="!deposit_address_loaded" />
        <div v-else>
            <div v-if="deposit_address_error">
                <div class="alert alert-danger small">
                    {{ deposit_address_error }}
                </div>
            </div>
            <div v-else class="deposit-address">
                <div>
                    <span id="deposit-addr">{{ deposit_addr }}</span>
                    <a ref="copyLink" href="javascript:;" class="qr-code" data-target="#deposit-addr">{{ $t('general.copy') }}</a>
                </div>
                <img v-bind:src="deposit_qr" alt="QR Code"  v-if="show_qr" v-on:click="toggle_qr()" />
                <a href="javascript:;" class="qr-code" v-on:click="toggle_qr()">{{ $t('balance.assets_deposit_qr') }}</a>
            </div>
        </div>
        <div class="help-block">
            <!-- deposit tips -->
            <div class="small" v-html="$t('balance.assets_deposit_tips', { ext_name: coinConfig.currency, min_deposit_amount: coinConfig.minDepositAmount })"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['bcConfig', 'coinConfig'],

    data() {
        return {
            active_currency: null,

            deposit_address_loaded: false,
            deposit_address_error: null,
            deposit_addr: null,
            deposit_qr: null,
            show_qr: false,

            // The default desp text for fiat deposits.
            fiat_desp: null,
        };
    },

    created() {
        // Special case for USDT.
        let token = this.coinConfig.currency;
        if (token === 'USDT')
            token = 'USDT_TRC';

        // Save the default description text for fiat deposits.
        this.fiat_desp = this.bcConfig.fiatDescription;

        this.toggle_deposit(token);
    },

    methods: {
        toggle_deposit: function (target_currency) {
            if (this.active_currency === target_currency) {
                return;
            }

            this.active_currency = target_currency;
            this.deposit_address_loaded = false;

            // query deposit address from server
            $.callPostApi(this, '/api/v1/blockchain/get_coin_address?coin=' + encodeURIComponent(target_currency))
                .then((json) => {
                    if (json.errcode === 0) {
                        this.deposit_addr = json.data.address;
                        this.deposit_qr = json.data.qr;
                        this.deposit_address_error = null;
                    } else {
                        this.deposit_address_error = json.errmsg;
                    }
                    this.deposit_address_loaded = true;

                    // Enable the copy link
                    const self = this;
                    Vue.nextTick(() => {
                        $(self.$refs.copyLink).enable_copy();
                    });
                })
                .catch((err) => { });
        },

        toggle_qr: function () {
            this.show_qr = !this.show_qr;
        },

        get_fiat_desp: function (lang) {
            if (typeof lang === 'string' && lang.length > 0) {
                // The language key will always be in lower case.
                return ((this.bcConfig && this.bcConfig.fiatDesps) ? this.bcConfig.fiatDesps[lang.toLowerCase()] : null) || this.fiat_desp;
            }

            // Return the default text.
            return this.fiat_desp;
        }
    }
};
</script>
<style scoped>
.qr-code{
   color: #006CFF;
   border-bottom: 1px solid;
}
.balance-list .nav-tabs .active{
    color: #006CFF ;
}
</style>