<template>
    <div class="content-bottom bg-content">
        <div class="mod-head bg-title">
            <div class="col">
                <div class="col-auto">
                    {{ $t('futures.label_open_orders') }}
                    <span v-if="!isNaN(totalProfit) && totalProfit != 0" class="text-content">
                        <span class="hidden-sm">({{ $t('futures.label_total_profit') }} {{ totalProfit.toFixed(4) }})</span>
                        <span class="d-sm-inline d-md-none small">({{ $t('futures.label_total_profit') }} {{ totalProfit.toFixed(4) }})</span>
                    </span>
                </div>
                <div class="col-auto col-action">
                     <button class="btn btn-primary trade-link" @click="onCloseAll" :disabled="!openOrders || openOrders.length === 0">{{ $t('futures.close_all') }}</button>
                    <router-link to="/futures/openorders" class="trade-link">{{ $t('orders.open_orders') }}</router-link>
                    <router-link to="/futures/orders" class="trade-link">{{ $t('futures.label_all_orders') }}</router-link>
                </div>
            </div>
        </div>
        <div class="mod-body">
            <section class="order-container">
                <table class="table text-center">
                    <thead>
                        <tr class="text-label">
                            <th class="hidden-sm">{{ $t('futures.order_create_time') }}</th>
                            <th>{{ $t('futures.order_symbol') }}</th>
                            <th>{{ $t('futures.order_direction') }}</th>
                            <th class="hidden-sm text-right">{{ $t('futures.order_avg_price') }}</th>
                            <th class="hidden-sm text-right">{{ $t('futures.order_current_price') }}</th>
                            <th class="text-right">{{ $t('futures.order_profit') }}</th>
                            <th class="text-right">{{ $t('futures.order_amount') }}</th>
                            <th class="text-right">{{ $t('futures.order_margin_pct') }}</th>
                            <th class="hidden-sm text-right">{{ $t('futures.order_action') }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="!openOrders">
                        <tr>
                            <td colspan="9">
                                <loading-indicator />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-if="openOrders.length === 0">
                            <td colspan="9">
                                <div class="no-data-indicator text-label">{{ $t('general.no_data') }}</div>
                            </td>
                        </tr>
                        <tr v-for="order in openOrders" :key="order.orderId" class="text-content">
                            <td class="hidden-sm">{{ new Date(order.timeCreated).formatDateTime() }}</td>
                            <td>{{ order.symbolName }}</td>
                            <td>
                                <span class="color-up" v-if="order.buyUp">{{ $t('futures.label_buy_up') }} </span>
                                <span class="color-down" v-else>{{ $t('futures.label_buy_down') }}</span>
                            </td>
                            <td class="hidden-sm text-right">{{ order.matchPrice }}</td>
                            <td class="hidden-sm text-right">{{ isNaN(order.currentPrice) ? '--' : order.currentPrice }}</td>
                            <td class="text-right">
                                <span v-if="isNaN(order.currentProfit)">--</span>
                                <span v-else>{{ order.currentProfit.toFixed(4) }}</span>
                            </td>
                            <td class="text-right">{{ order.numHands }}</td>
                            <td class="text-right">{{ 100 / order.leverage }}%</td>
                            <td class="hidden-sm text-right">
                                <a href="javascript:;" @click="doConfirmClosingOrder(order)" class="trade-link"><b>{{ $t('futures.label_close_order') }}</b></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>

        <close-order-modal ref="closeOrderModal" @order-closed="onOrderClosed" />
        <close-all-modal ref="closeAllModal" @order-closed="onOrderClosed" />
    </div>
</template>

<script>
let g_destroyed = true;
let g_order_timer_id = 0;
let g_quote_timer_id = 0;
let g_priceMap = {};
let g_balance = null;

import CloseOrderModal from './Futures_CloseOrderModal.vue';
import CloseAllModal from './Futures_CloseAllOrdersModal.vue';

export default {
    components: { CloseOrderModal, CloseAllModal },

    data() {
        return {
            totalProfit: NaN,
            openOrders: null,

            toCloseOrder: null,
        };
    },

    created() {
        // clear all existing prices
        g_priceMap = {};

        // Mark the component as active.
        g_destroyed = false;
    },

    beforeDestroy() {
        g_destroyed = true;
        clearTimeout(g_order_timer_id);
    },

    mounted() {
        this.readStats();
    },

    methods: {
        onOrderClosed: function () {
            this.readStats();
        },

        onCloseAll: function () {
            this.$refs.closeAllModal.openModal();
        },

        readStats: function () {
            clearTimeout(g_order_timer_id);

            const self = this;
            function func() {
                $.post('/api/v1/futures/stats')
                    .done(function (stats) {
                        // update quotes
                        g_balance = stats.balance || { balance: 0, frozen: 0 };

                        let orders = stats.openOrders;
                        if (orders && orders.length > 0) {
                            // Remember all existing orders.
                            const current_map = {};
                            if (self.openOrders) {
                                $(self.openOrders).each((_, order) => {
                                    current_map[order.orderId] = order;
                                });
                            }

                            const output = [];
                            $(orders).each((_, order) => {
                                if (current_map[order.orderId]) {
                                    // Do not replace existing open orders.
                                    // Otherwise, its realtime price/profit information will be lost. And most importantly, it won't be updated in popup modals like Close Order modal.
                                    //
                                    output.push(current_map[order.orderId]);
                                } else {
                                    output.push(order);

                                    // Update its profit using the latest price
                                    const sid = order.symbolId;
                                    const price = g_priceMap[sid];
                                    if (!isNaN(price)) {
                                        self._updateOrderProfit(order, sid, price);
                                    }
                                }
                            });

                            orders = output;
                        } else {
                            // If no open orders, then the net profit is zero.
                            self._updateRealtimeBalance(0);
                        }
                        self.openOrders = orders;

                        // Start to read quotes for these orders.
                        self._doReadQuotes();
                    })
                    .always(function () {
                        if (g_destroyed === false) {
                            clearTimeout(g_order_timer_id);
                            g_order_timer_id = setTimeout(func, 6000);
                        }
                    });
            }

            // Read stats in a delay.
            if (g_destroyed === false) {
                clearTimeout(g_order_timer_id);
                g_order_timer_id = setTimeout(func, 500);
            }
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        _doReadQuotes: function () {
            const self = this;
            clearTimeout(g_quote_timer_id);

            // BUGFIX: do not run the timer when the component was destroyed
            if (g_destroyed !== false) {
                return;
            }

            if (!self.openOrders) {
                g_quote_timer_id = setTimeout(self._doReadQuotes, 2000);
            } else {
                // get an unique set of symbols
                const map = {};
                const sids = [];

                for (let i = 0; i < self.openOrders.length; i++) {
                    const sid = self.openOrders[i].symbolId;
                    if (map[sid] !== true) {
                        sids.push(sid);
                        map[sid] = true;
                    }
                }

                if (sids.length > 0) {
                    $.get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                        .done(function (quotes) {
                            // update quotes
                            for (let i = 0; i < quotes.length; i++) {
                                self._updateRealtimePrice(quotes[i].id, quotes[i].c);
                            }
                        })
                        .always(function () {
                            clearTimeout(g_quote_timer_id);

                            if (g_destroyed === false) {
                                // refresh quotes in 5 seconds.
                                g_quote_timer_id = setTimeout(self._doReadQuotes, 5000);
                            }
                        });
                } else {
                    clearTimeout(g_quote_timer_id);

                    if (g_destroyed === false) {
                        // In case some open order were created somewhere else.
                        g_quote_timer_id = setTimeout(self._doReadQuotes, 2000);
                    }
                }
            }
        },

        doConfirmClosingOrder: function (order) {
            this.$refs.closeOrderModal.openModal(order);
        },

        /*
         * ======================================================================================
         * cancel a pending futures order
         */
        // doCancelOrder: function (orderId) {
        //     const self = this;
        //     if (orderId && orderId.length) {
        //         // *************************************************************
        //         // Post to server to cancel an order
        //         $.post('/api/v1/futures/cancelorder?id=' + encodeURIComponent(orderId))
        //             .done(function (json) {
        //                 if (json && json.errcode === 0) {
        //                     $.top_alert(self.$t('general.submitted'));

        //                     // Try to load the pending order
        //                     self.start_load_pending_orders();
        //                 } else {
        //                     $.top_error(json.errmsg);
        //                 }
        //             })
        //             .fail(function (err) {
        //                 $.top_error(self.$t('general.operation_error'));
        //                 return;
        //             });
        //     }
        // },

        ///////////////////////////////////////////////////////////////////////////////////////////
        /**
         * Update price for a symbol.
         */
        updatePrice: function (sid, price) {
            this._updateRealtimePrice(sid, price);
        },

        _updateOrderProfit: function (order, sid, price) {
            if (order.symbolId === sid) {
                var profit = (price / order.matchPrice - 1) * order.numHands * /* order unit */ 1000;
                if (!order.buyUp) profit = -profit;

                order.currentPrice = price;
                order.currentProfit = profit;
            }

            // Return the current profit.
            return order.currentProfit;
        },

        _updateRealtimePrice: function (sid, price) {
            g_priceMap[sid] = price;
            const self = this;

            // Calculate the realtime profit for the user
            if (price > 0.00000001) {
                let total_profit = 0;
                if (this.openOrders) {
                    for (let i = 0; i < this.openOrders.length; i++) {
                        let raw_order = this.openOrders[i];
                        let profit = self._updateOrderProfit(raw_order, sid, price);
                        total_profit += profit;

                        // Missing quotes for the order?
                        if (isNaN(total_profit)) break;
                    }
                }

                self._updateRealtimeBalance(total_profit);
            }
        },

        /*
         * ======================================================================================
         * Update current balance for the user.
         */
        _updateRealtimeBalance: function (net_profit) {
            this.totalProfit = net_profit;
            if (g_balance && !isNaN(net_profit)) {
                let margin = Math.max(0, Math.min(g_balance.balance, g_balance.balance + g_balance.frozen));

                // UPDATE:
                // Profit amount is alos available for creating orders.
                //
                // if (net_profit < 0) {
                //     margin += net_profit;
                // }
                margin += net_profit;

                // this.balance = (g_balance.balance + net_profit).toFixed(4);
                this.availableMargin = margin;

                // Notify the update of the available margin.
                this.$emit('available-margin-updated', margin);
            } else {
                // this.balance = '--';
                this.availableMargin = NaN;
                this.$emit('available-margin-updated', NaN);
            }
        }
    }
};
</script>
<style scoped>
.col-action{
    position: absolute;
    right: 0;
}
@media(max-width:768px) {
    .col-action{
        font-size: 10px;
    }
}
.col-action >.trade-link{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #2E86FE;
    background: #fff;
    opacity: 1;
}
.col-action button{
    margin-bottom: 5px;
}
.col-action a{
    margin-right: 6px;
}
.mod-body{
    margin-top: 30px;
}
</style>