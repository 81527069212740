<template>
    <div class="w-100 prompted-symbol">
        <div class="symbol-content">
            <div class="title-box">
                <img src="@/assets/images/de-twaa/stars-black.png" alt="">
                <token-icon :symbol="symbol" />
                <h2>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
            </div>
        </div>
        <div class="price1">
            <div>{{ '$' + symbol.price }}</div>
            <div class="price-white">{{ '/￥' + (symbol.price*7.085).toFixed(2) }}</div>
        </div>
        
        <div class="col-auto">
            <div class="d-flex">
                <div class="price px-2 flex-column">
                    {{ $t('home.24h_high') }}
                    <div>
                        {{ symbol.day_high }}
                    </div>
                </div>
                <div class="price px-2 flex-column">
                    {{ $t('home.24h_low') }}
                    <div>
                        {{ symbol.day_low }}
                    </div>
                </div>
                <div class="price px-2 flex-column">
                    {{ $t('home.24h_change') }}
                    <div :class="symbol.up ? 'price-green' : 'price-red'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
                </div>
            </div>
        </div>
        <div class="trade-button" @click="gotoTrade(symbol)">
            {{ $t('general.trade') }}
        </div>
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        },
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },
    }
};
</script>

<style scoped>
.prompted-symbol{
    color: #000;
    background-color: #fff;
    border-radius: 20px 20px 20px 20px;
    padding-bottom: 1.5rem;
    height: 100%;
}
.prompted-symbol .token-icon>* {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background-size: cover;
    background-repeat: no-repeat;
}
.title-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #000;
    font-style: normal;
    text-transform: none;
}

.symbol-content {
    border-radius: 0.5rem;
    padding: 1.5rem 30px 0;
    line-height: 2;
}

.col-auto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}
.col-auto .d-flex {
    justify-content: space-around;
}

.symbol-content h2 {
    font-size: 2rem;
    color: #000;
    margin-bottom: 0;
}

.symbol-content .up {
    color: var(--up);
    padding-left: 40px;
}

/* .symbol-content .slide-price {
    color: #acafbe;
} */
.symbol-content .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}

.symbol-content .down {
    color: var(--down);
}

.symbol-content .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}

.price1 {
    display: flex;
    color: #04C424;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 30px 10px 54px;
    white-space: nowrap;
}
.price-white{
    color: #000;
}
.price-green{
    color: #04C424;
}
.price-red{
    color: #e55716;
}
.price1.px-2 {
    color: #000;
    font-size: 10px;
    font-weight: 400;
    padding: 2% 4% 2% 4%;
}
.trade-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    padding: 2% 1% 2% 1%;
    margin: 2% 3% 0 13%;
    background: #000000;
    border-radius: 12px 12px 12px 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 17px;
    color: #FFFFFF;
    font-style: normal;
    text-transform: none;
    cursor: pointer;
}
.page-top .prompted-symbol .price{
    background: #fff;
    color: #000;
    font-size: 10px;
    white-space: nowrap;
}
.prompted-symbol .price{
    background: #fff;
}
@media (max-width:1024px) {
    .w-100.prompted-symbol {
        font-size: 10px;
        width: 20px;
    }

    .price1 {
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 4px;
        font-size: 18px;
    }

    .price1.px-2 {
        color: #fff;
        font-size: 12px;
        font-weight: normal;
    }

    .price.px-2 {
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 0;
        font-weight: normal;
        font-family: PingFangSC, PingFang SC;
        font-size: 12px;
    }
    .col-auto{
        padding-left: 0;
    }
    .trade-button{
        font-size: 8px;
    }
}
@media(max-width:767px){
    .symbol-content h2 {
        font-size: 1rem;
    }
    .w-100.prompted-symbol {
        font-size: 10px;
        width: 180px !important;
    }
    .price1{
        font-size: 9px;
        padding-left: 16px;
    }
    .price.px-2{
        font-size: 6px;
    }
    .page-top .prompted-symbol .price{
        font-size: 6px;
        white-space: wrap;
    }
}
@media(max-width:376px){
    .symbol-content h2 {
        font-size: 1rem;
    }
    .w-100.prompted-symbol {
        font-size: 10px;
        width: 153px !important;
    }
    .price1{
        font-size: 9px;
        padding-left: 16px;
    }
    .price.px-2{
        font-size: 6px;
    }
}
</style>